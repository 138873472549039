export const treeParentRecursive = (node, parentArray) => {
  if (node.treeParent) {
    parentArray.push(node.treeParent.slug);
    treeParentRecursive(node.treeParent, parentArray);
  } else if (node.parentHub) {
    parentArray.push(node.parentHub.slug);
    treeParentRecursive(node.parentHub, parentArray);
  } else if (node.parentPage) {
    parentArray.push(node.parentPage.slug);
    treeParentRecursive(node.parentPage, parentArray);
  }
}

export const generateSlug = (nodeSlug, parentArray) => {
  parentArray.reverse()
  let slug = "";

  if (parentArray.length > 0) {
    slug = '/';
    for (let p = 0; p < parentArray.length; p++) {
      const parent = parentArray[p];
      if (parent == 'home') {
        slug = slug;
      } else {
        slug = slug + `${parent}/`;
      }
    }
    slug = slug + nodeSlug;
  } else if (nodeSlug == 'home') {
    slug = `/`
  } else {
    slug = nodeSlug;
  }
  if (!slug.startsWith('/')) slug = '/' + slug;
  return slug
}