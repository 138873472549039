import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { debounce } from '../../../helpers/debounce'
import highlightWords from 'highlight-words';
import stripHtml from "striptags";
import Fuse from 'fuse.js'
// @ts-ignore
import SearchIcon from '../../../svg/search.svg'
// @ts-ignore
import Close from '../../../svg/close-x.svg'



interface props {
  setNavSearchActive: Function,
  defaultKeyword:string|null,
  setClosed:Function
}

let buildTags = false;
let tags = [];
let searchValue = '';
let keyDownSet = false;
let searchedManually = false;
let closed = false;
export default function NavSearchDesktop({setNavSearchActive, defaultKeyword, setClosed}: props) {
  
  const { 
    basepageData,
    articleData,
    faqData,
    hubData
  } = componentData();
  const [searchActive, setSearchActive] = useState(false);
  const [predictiveItems, setPredictiveItems] = useState([]);
  const [showPredictiveItems, setShowPredictiveItems] = useState(false);
  const [itemFocus, setItemFocus] = useState(-1);
  const searchInput = useRef(null);

  

  let searchKeyboardEvent = null;
  let searchClickEvent = null;
  let enterTimeout = false;


  
  
  
  useEffect(()=>{
    
    if(defaultKeyword && !searchedManually && !closed){
        defaultKeyword = defaultKeyword.replace(new RegExp("__pct__", "igm"), "%");
        defaultKeyword = defaultKeyword.replace(new RegExp("__and__", "igm"), "&");

        

        searchValue = searchInput.current.value = defaultKeyword;
        searchInput.current.focus();      
        setSearchActive(true);
        enterTimeout = true;      
        
    }
  })
  useEffect(() => {
    if (!buildTags) {
      buildTagArray();
      buildTags = true;
    }
    
    if (!enterTimeout) setTimeout(() => enterTimeout = true, 200);
    
    setSearchKeydown();
    setSearchClickOut();
   
    return () => {
      document.removeEventListener('keyup', searchKeyboardEvent)
      document.getElementById('main-content').removeEventListener("mousedown", searchClickEvent)
    };
  })

  const searchButton = (): void => {
    setSearchActive(true);
    setNavSearchActive(true);
    //searchInput.current.value = '';
    searchInput.current.focus();
  }
  
  const buildTagArray = (): void => {
    let buildArray = [];

    // Pages
    for (let i = 0; i < basepageData.edges.length; i++) {
      const rawPage = basepageData.edges[i].node;
      if (rawPage.slug !== 'home' && !rawPage.campaignPage) {
        buildArray.push(rawPage.pageTitle);
        if (rawPage.searchTags.length > 0) {
          for (let z = 0; z < rawPage.searchTags.length; z++) {
            const searchTag = rawPage.searchTags[z].searchTag;
            buildArray.push(searchTag);  
          }
        }
      }
    }

    // Hubs
    for (let i = 0; i < hubData.edges.length; i++) {
      const rawPage = hubData.edges[i].node;
      buildArray.push(rawPage.pageTitle);
      
      if (rawPage.searchTags && rawPage.searchTags.length > 0) {
        for (let z = 0; z < rawPage.searchTags.length; z++) {
          const searchTag = rawPage.searchTags[z].searchTag;
          buildArray.push(searchTag);  
        }
      }
    }

    // Articles
    for (let i = 0; i < articleData.edges.length; i++) {
      const rawArticle = articleData.edges[i].node;
      buildArray.push(stripHtml(rawArticle.title));
      if (rawArticle.searchTags.length > 0) {
        for (let z = 0; z < rawArticle.searchTags.length; z++) {
          const searchTag = rawArticle.searchTags[z].searchTag;
          buildArray.push(searchTag);  
        }
      }
    }

    // Accordions
    for (let i = 0; i < faqData.components.length; i++) {
      const faqComponent = faqData.components[i];
      if (faqComponent.__typename == 'DatoCmsAccordionModule' && faqComponent.accordionItems.length > 0) {
        for (let a = 0; a < faqComponent.accordionItems.length; a++) {
          const item = faqComponent.accordionItems[a];
          buildArray.push(item.title);  
          if (item.searchTags.length > 0) {
            for (let z = 0; z < item.searchTags.length; z++) {
              const searchTag = item.searchTags[z].searchTag;
              buildArray.push(searchTag);  
            }
          }
        }
      }
    }

    // Remove duplicates and add to tag array
    tags = removeDuplicates(buildArray);
  }

  const removeDuplicates = (array) => {
    let x = {};
    array.forEach(function(i) {
      if(!x[i]) {
        x[i] = true
      }
    })
    return Object.keys(x)
  };
 
  const setSearchKeydown = () => {
    //document.removeEventListener("keyup", searchKeyboardEvent); //remove first
    keyDownSet = true;
    document.addEventListener("keyup", searchKeyboardEvent = debounce(function (e) {
      //console.trace();
      if (searchActive) {
        e = e || window.event;
        const currentSearchValue = searchInput.current.value;

        // Start predictions
        if (searchValue !== currentSearchValue) {
          if (currentSearchValue.length >= 3) {
            predictKeyword(currentSearchValue);
          } else if (currentSearchValue.length < 3) {
            setShowPredictiveItems(false);
          }
          searchedManually = true;
        }
        
        // Escape key
        if (e.keyCode == 27) {
          close()
        }

        // Enter key
        //console.log("cond 1", (e.keyCode == 13 && searchActive && searchValue !== '' && itemFocus == -1 && enterTimeout));
        //console.log("cond 2", (e.keyCode == 13 && searchActive && itemFocus !== -1));
        if (e.keyCode == 13 && searchActive && searchValue !== '' && itemFocus == -1 && enterTimeout) searchFor(searchValue);
        if (e.keyCode == 13 && searchActive && itemFocus !== -1) searchFor(predictiveItems[itemFocus].item);

        // DOWN
        if (e.keyCode == 40) {
          if (itemFocus < predictiveItems.length - 1) setItemFocus(itemFocus + 1);
        }
        // UP
        if (e.keyCode == 38) {
          if (itemFocus >= 0) setItemFocus(itemFocus - 1);
        }

        // Set search value
        //console.log("search value", currentSearchValue)
        searchValue = currentSearchValue;
      }
    }, 100), false);
    


  }

  const predictKeyword = async (input) => {
    const options = {
      includeScore: true,
      includeMatches: true,
      threshold: 0.2,
      location: 0,
      distance: 500,
      maxPatternLength: 32,
    }
    const fuse = new Fuse(tags, options)
    const result = fuse.search(input);
        
    setPredictiveItems(result.slice(0, 10));

    if (result.length > 0) setShowPredictiveItems(true);
  }

  const setSearchClickOut = () => {
    document.getElementById('main-content').addEventListener("mousedown", searchClickEvent = (e) => {
      if (searchActive) {
        setSearchActive(false);
        setNavSearchActive(false);
      }
    }, false);
  }

  const close = () => {
    closed = true;
    searchedManually = true;
    setSearchActive(false);
    setNavSearchActive(false);
    setShowPredictiveItems(false);
    setItemFocus(-1);
    setPredictiveItems([]);
    setClosed(true);
    history.pushState(null, "", location.href.split("?")[0]);
  }

  function searchFor(value) {

    if (value !== '') {
      
      close();
      
      value = value.replace(new RegExp("%", "igm"), "__pct__");
      value = value.replace(new RegExp("&", "igm"), "__and__");
      //navigate(`/search?q=${value}`, {state: { value }});
      document.location.href = `/search?q=${value}`;
    }
  }

  return (
    <>
      <button 
        onClick={searchButton}
        className="nav__search-button"
        aria-label={`Search Button`}
      >
        <SearchIcon/>
      </button>
      <div className="nav__search-container">
        <SearchIcon
          className={`nav__search-icon`}
          onClick={() => searchFor(searchInput.current.value)}
        />
        <input 
          type="text" 
          className="nav__search-input"
          ref={searchInput}
          onFocus={() => setItemFocus(-1)}
        />
        <div className={`nav__search-predictive ${showPredictiveItems && predictiveItems.length > 0 ? 'active' : ''}`}>
          {predictiveItems.map((item, i) => {
            // Highlight words of the query that just letters
            var highlightWordsOnly = searchInput.current.value.replace(/(\b(\w{1,1})\b(\s|$))/g,'');
            
            return (
              <button 
                className={`nav__search-predictive-item ${itemFocus === i ? 'active' : ''}`}
                key={`key-${i}`}
                onFocus={() => setItemFocus(i)}
                onClick={() => searchFor(item.item)}
              >
                {highlightWords({text: item.item, query: highlightWordsOnly}).map((text, index) => (
                  <span 
                    key={`text-chunk-${index}`} 
                    className={`${text.match ? 'bold' : ''}`}
                  >
                    {text.text}
                  </span>
                ))}
                {/* {item.item} */}
              </button>
            )
          })}
        </div>
        <div className="nav__search-buttons">
          <button 
            className="nav__search-clear"
            onClick={() => {
              history.pushState(null, "", location.href.split("?")[0]);
              setPredictiveItems([]);
              searchInput.current.value = '';
              
              searchInput.current.focus();
              closed = true;
              
            }}
          >
            Clear
          </button>
          <button 
            className="nav__search-close"
            onClick={close}
          >
            <Close/>
          </button>
        </div>
      </div>
    </>
  )
}

const componentData = () => {  
  const data = useStaticQuery(graphql`
    query {
      searchTags: allDatoCmsGlobalSearchTag {
        edges {
          node {
            searchTag
          }
        }
      }
      basepageData: allDatoCmsBasePage {
        edges {
          node {
            ...searchBasePageFragment
          }
        }
      }
      articleData: allDatoCmsArticlePage {
        edges {
          node {
            ...searchArticlePageFragment
          }
        }
      }
      hubData: allDatoCmsArticleHub {
        edges {
          node {
            ...searchHubPageFragment
          }
        }
      }
      faqData: datoCmsBasePage(slug: {eq: "faq"}) {
        ...searchFaqPageFragment
      }
    }
  `)
  return data
}