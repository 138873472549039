import React from 'react';
import { Helmet } from 'react-helmet';

export default function Webchat() {
  
  return (
    <Helmet>
      <script src="/webchat/webchat.js"/>
    </Helmet>
  )
}


