import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Cookies from 'js-cookie'
import { parseHTML } from './../../helpers/parseHTML';
// @ts-ignore
import Close from '../../svg/close-x.svg'

// @ts-ignore
import CookiesIcon from '../../svg/cookie-icon.svg'

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function Accordions() {
  const data = componentData();
  const cookies = Cookies.get();
  const cookiesBanner = useRef(null);
  let cookieBannerMounted = false;
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [cookieConsentGiven, setCookieConsentGiven] = useState(
    typeof window !== 'undefined' && cookies.cookiesPolicyAccepted ? true : false
  );

  const handleClick = (action) => {
    if (action == 'dismiss') {
      Cookies.set("cookiesPolicyAccepted", "true", { expires: 2 })
      cookiesBanner.current.classList.add('hidden')
      setTimeout(() => setCookieConsentGiven(true), 500);
    }
  }

  useEffect(() => {
    // // for testing purposes
    // Cookies.remove("cookiesPolicyAccepted")
    // // console.log(cookies)
    // setCookieConsentGiven(false)

    if (!cookieBannerMounted) {

      setTimeout(() => {
        const cookies = Cookies.get()
        if (cookies.cookiesPolicyAccepted === undefined) {
          if (window) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
              "event": "cookies_consent_given",
              "cookie_consent": "true"
            })
          }
          setShowCookieConsent(true)
        }
      }, 500);
      cookieBannerMounted = true;
    }
  }, [])

  return (
    <>
      {showCookieConsent ?
        <section
          id="cookie-consent-container"
          aria-label="cookies consent dialog box"
          className={`cookies ${cookieConsentGiven ? 'hidden' : ''}`}
          role="dialog"
          ref={cookiesBanner}
        >
          <div className="outer-container">
            <div className="inner-container">
              <div className="cookies__content">
                <CookiesIcon className={`cookies__content-icon ${data.title ? 'cookies__content-icon--align-top' : ''}`} />
                <div className="cookies__content-text">
                  <div className="cookies__content-title-container">
                    {data.title ?
                      <>
                        <CookiesIcon className="cookies__content-icon mobile" />
                        <p className="cookies__content-title">
                          {data.title}
                        </p>
                      </>
                      : null}
                  </div>
                  <div className="rte">
                    {parseHTML(data.text)}
                  </div>
                </div>
              </div>
              <div className="cookies__cta">
                <button
                  id="cookie-consent-btn"
                  aria-aria-labelledby="cookie-consent-btn-msg"
                  className={`cookies__cta-btn ${data.title ? 'cookies__cta-btn--align-top' : ''}`}
                  onClick={() => handleClick('dismiss')}
                >
                  <span id="cookie-consent-btn-msg" className="cookies__cta-label">Dismiss cookie policy message</span>
                  <svg role="presentation" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5924 11.79L22.9996 20.1972C23.7735 20.9711 23.7735 22.2257 22.9996 22.9996C22.2257 23.7735 20.9711 23.7735 20.1972 22.9996L11.79 14.5924L3.3828 22.9996C2.60894 23.7735 1.35426 23.7735 0.580396 22.9996C-0.193465 22.2257 -0.193465 20.9711 0.580396 20.1972L8.9876 11.79L0.580396 3.3828C-0.193465 2.60894 -0.193465 1.35426 0.580396 0.580396C1.35426 -0.193465 2.60894 -0.193465 3.3828 0.580396L11.79 8.9876L20.1972 0.580396C20.9711 -0.193465 22.2257 -0.193465 22.9996 0.580396C23.7735 1.35426 23.7735 2.60894 22.9996 3.3828L14.5924 11.79Z" fill="#858585" />
                  </svg>

                </button>
              </div>
            </div>
          </div>
        </section>
        : null}
    </>
  )
}

const componentData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsCookiesDisclaimer {
        title
        text
      }
    }
  `)
  return data
}