import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link';

interface props {
  to: string
  ariaLabel?: string
  children: any
  className?: string
  onClick?: Function
  onMouseEnter?: Function
  onFocus?: Function
  onBlur?: Function
  delay?: number
  state?: any
  excerpt?:string
}

export default function AnimatedLink(props: props) {
  let { onClick, delay, state, className, to, children, onMouseEnter, onFocus, ariaLabel, onBlur, excerpt } = props;
  let entry = {delay: delay !== undefined ? delay : 0.4, length: 0}
  let finalEntry = {};

  if (state !== undefined) {
    finalEntry = Object.assign(entry, state)
  } else {
    finalEntry = entry;
  }

  function checkOnClick() {
    
    if (onClick !== undefined){
      onClick();
    } else if(props.to){
      document.location.href = props?.to;
    }
  }

  function checkMouseEnter() {
    if (onMouseEnter !== undefined) onMouseEnter()
  }

  function checkFocus() {
    if (onFocus !== undefined) onFocus()
  }

  function checkBlur() {
    if (onBlur !== undefined) onBlur()
  }
  
  return (
    <TransitionLink
      to={to || ''}
      className={className !== undefined ? className : ''}
      onClick={checkOnClick}
      onMouseEnter={checkMouseEnter}
      aria-label={ariaLabel}
      onFocus={checkFocus}
      onBlur={checkBlur}
      preventScrollJump
      trigger={() => setTimeout(() => window.scrollTo(0,0), 400)}
      entry={finalEntry}
      exit={{
        length: 0.4
      }}
    >
      {children}
      {excerpt ? <div class="product-link__content-text">{excerpt}</div> : null}
    </TransitionLink>
  )
}