import React from 'react';
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby';

const componentData = () => {  
  const data = useStaticQuery(graphql`
    query {
      data: site {
        siteMetadata {
          author
          description
          siteUrl
          title
        }
      }
      datoSeo: datoCmsSite {
        globalSeo {
          siteName
          titleSuffix
          fallbackSeo {
            description
            image {
              url
            }
          }
        }
      }
    }
  `)
  return data
}

export default function SEO({ 
  metaDescription,
  ogDescription,
  ogTitle,
  ogImage,
  lang,
  meta,
  keywords,
  title,
  bodyType,
  noindex,
 }) {
  const { data, datoSeo } = componentData();
  const datoSeoItems = datoSeo.globalSeo;
  const metaDes = metaDescription || datoSeoItems.fallbackSeo.description || data.siteMetadata.description;
  const ogImageUrl = ogImage !== null ? ogImage.url : datoSeoItems.fallbackSeo.image ? datoSeoItems.fallbackSeo.image.url : '';
  const noindexMeta = noindex ? {
    name: `robots`,
    content: `noindex`,
  } : {};
  
  return (
    <Helmet
      htmlAttributes={{lang}}
      bodyAttributes={{class: bodyType}}
      title={title}
      titleTemplate={`%s ${datoSeo.globalSeo.titleSuffix || `| Bankvic`}`}
      meta={[
        noindexMeta,
        {
          name: `description`,
          content: metaDes,
        },
        {
          property: `og:title`,
          content: ogTitle ? ogTitle : `${title} ${datoSeo.globalSeo.titleSuffix || `| Bankvic`}`,
        },
        {
          property: `og:description`,
          content: ogDescription ? ogDescription : metaDes,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : [],
        )
        .concat(meta)}
    >
      <base href="/" target="_blank"/> 
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  bodyType: '',
  image: '',
  ogImage: null
};