import React, { useEffect } from 'react'
import { startupTasks } from '../../helpers/accessibility';
import { useDispatch } from 'react-redux';
import ReadingProgress from 'react-reading-progress-plus'
import { bind } from 'size-sensor';
import 'focus-within-polyfill'
import 'mdn-polyfills/Array.prototype.forEach';
import '../../scss/main.scss'
import { useSelector } from 'react-redux';
import NavAlert from './../global/nav/nav-alert';
import queryString from 'query-string';
import animateScrollTo from 'animated-scroll-to';
import { setPathName } from './../../state/state-site';
import { useLocation } from '@reach/router';
import CookiesDisclaimer from "../global/cookies-disclaimer"
import Webchat from './../global/webchat';
import FormaticScript from '../global/formatic-script';

interface props {
  children: any
  transitionStatus: string
  className?: string
  campaignPage?: any
  isWebView?: boolean
}

let startupTasksCompleted = false;

export default function Layout({ children, transitionStatus, className, campaignPage, isWebView }: props) {
  const dispatch = useDispatch();
  const menuOpen = useSelector(state => state.siteState.menuOpen);
  const navAlert = useSelector(state => state.siteState.showAlertBar);
  let queryStringChecked = false;
  let unbind = null;
  let progressBar = false;
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setPathName(pathname));
    if (!startupTasksCompleted) {
      startupTasks(dispatch);
      startupTasksCompleted = true;
    }
    if (!queryStringChecked) {
      checkStickyQueryString();
      queryStringChecked = true;
    }
    if (!progressBar) {
      unbind = bind(document.getElementById('main-content'), () => {
        updateProgressBar();
      });
    }
    return () => unbind();
  }, []);

  function updateProgressBar() {
    let progressBar = document.getElementsByTagName('progress')[0];
    if (progressBar !== undefined) {
      let contentContainer = document.getElementById('main-content');
      let containerHeight = contentContainer.offsetHeight - window.innerHeight;
      // @ts-ignore
      progressBar.setAttribute('max', containerHeight);
    }
  }

  function checkStickyQueryString() {
    const parsed = queryString.parse(location.search);
    if (parsed.section) {
      let stickyNavSection = document.getElementById(parsed.section as string);
      setTimeout(() => {
        if (stickyNavSection) animateScrollTo(stickyNavSection.offsetTop, { speed: 2000 })
      }, 400);
    }
  }

  return (
    <>
      <ReadingProgress targetEl="#main-content" className={`${menuOpen ? 'menu-open' : ''}`} />
      <main
        tabIndex={0}
        id="main-content"
        className={`${className} ${transitionStatus} ${navAlert ? 'alert' : ''}`}
        style={isWebView && { 'marginTop': 0}}
      >
        <FormaticScript />
        <Webchat />
        <CookiesDisclaimer />
        {!campaignPage &&
          <NavAlert mobile={true} />
        }
        {children}
      </main>
    </>
  )
}