import React from "react";

interface PdfLinkModel {
  pdfLink?: IbPdfModel;
  externalPdfUrl?: string;
  className?: string;
  isExternal: boolean;
  isDownload: boolean;
  onClick?: any | null;
  onMouseEnter?: any | null;
  children?: any;
  title?: string;
  label?: string;
}

export interface IbPdfModel {
  resourceId: string;
  name: string;
  description?: string;
  filePath: string;
  fileSize: string;
}

export default function PdfLink({
  pdfLink,
  className = "",
  isExternal = false,
  isDownload = false,
  externalPdfUrl,
  onClick,
  onMouseEnter,
  children,
  title = "",
  label = "",
}: PdfLinkModel) {
  
  return (
    <a
      aria-label={label}
      href={isExternal ? externalPdfUrl : pdfLink.filePath}
      target="_blank"
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      title={title || pdfLink.description || ""}
      download={isDownload}
    >
      {children || `${pdfLink.name} (${pdfLink.fileSize})`}
    </a>
  );
}

