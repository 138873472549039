import { isMobile, isTablet } from 'react-device-detect';
import { detect } from 'detect-browser';
import objectFitImages from 'object-fit-images';
import { setBrowser } from './../state/state-site'

export function startupTasks(dispatch) {  
  if (!isMobile && !isTablet && !window.keyboardSet) {
    keyboardNavigation();
    resetKBAccessibility();
  } 
  objectFitImages();
  browserDetect(dispatch);
}

export function keyboardNavigation(e) {
  window.keyboardSet = true;
  keyboardNavigation = null;
  document.addEventListener("keydown", function keyboardNavigationEvent(e) {
    e = e || window.event;
    switch (e.keyCode) {
      case 9:
        let body = document.body;
        body.classList.add("kb-accessibility");
        break;
      default:
        break;
    }
  }, false);
}

export function resetKBAccessibility() {
  document.addEventListener("mousedown", function resetAccessibility(e) {
    let body = document.body;
    body.classList.remove("kb-accessibility")
  }, false);
}

export function browserDetect(dispatch) {
  const browser = detect();
  let gatsbyEl = document.querySelector("#___gatsby");
  gatsbyEl.classList.add(browser.name);
  dispatch(setBrowser(browser.name))
}