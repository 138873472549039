import React from 'react'
import { Helmet } from 'react-helmet';
const FormaticScript = () => {
  return (
    <Helmet>
      <script src="/formatic/formatic.js" />
      <script src="/formatic/global.js" />
    </Helmet>
  )
}

export default FormaticScript;
