import React, { useState, useEffect } from 'react';
import AnimatedLink from '../animatedLink';
import dayjs from 'dayjs';
import { setAlertBarDetails, setShowAlertBar, setAlertBarOffset } from '../../../state/state-site';
import { useStaticQuery, graphql } from 'gatsby';
// @ts-ignore
import AlertBell from '../../../svg/alert-bell.svg'
// @ts-ignore
import ArrowRightBlue from '../../../svg/arrow-right-blue.svg'
import { useSelector, useDispatch } from 'react-redux';
import SmartLink from './../smart-link';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie'

let checkedAlertBar = false;

interface props {
  mobile?: any
}

export default function NavAlert({ mobile }: props) {
  const { alertBar } = componentData();
  const cookies = Cookies.get();
  if (!alertBar) return <></>;

  async function checkForAlertBar() {
    if (dayjs().isAfter(alertBar.alertBarStartTime) && dayjs().isBefore(alertBar.alertBarEndTime)) {
      setTimeout(function () {
        dispatch(setAlertBarDetails({...alertBar}));
        enableAlertBar()
      }, 1000);
    }
  }

  const enableAlertBar = () => {
    if(cookies.alertBarDismissed === undefined) {
      let alertHeight = 0;
      if (isMobile) {
        alertHeight = document.querySelector('.nav-alert.mobile').scrollHeight;
        document.body.classList.add('alert-bar-active');
        dispatch(setShowAlertBar(true));
        dispatch(setAlertBarOffset(alertHeight));
      } else {
        alertHeight = document.querySelector('.nav-alert').scrollHeight;
        document.getElementById('main-content').style.paddingTop = alertHeight + 'px';
        document.body.classList.add('alert-bar-active');
        dispatch(setShowAlertBar(true));
        dispatch(setAlertBarOffset(alertHeight));
      }
    }
    
  }

  const dismissAlert = () => {
    dispatch(setShowAlertBar(false));
    document.body.classList.remove('alert-bar-active');
    document.getElementById('main-content').style.paddingTop = null;
    Cookies.set("alertBarDismissed", "true", { expires: 1 });
    dispatch(setAlertBarOffset(0));
  }

  
  useEffect(() => {
    if (!checkedAlertBar) {
      // @ts-ignore
      checkForAlertBar();
      checkedAlertBar = true;
    }
  })

  const { showAlertBar, alertBarDetails } = useSelector(state => state.siteState);
  const dispatch = useDispatch();

  return (
    <div
      aria-hidden={showAlertBar ? false : true}
      className={`nav-alert ${showAlertBar ? 'active' : ''} ${mobile  ? 'mobile' : ''}`}
    >
      <div className="inner-container">
        <AlertBell className={`nav-alert__bell`}/>
        <p className="nav-alert__text">{alertBarDetails.alertBarText}</p>
        {alertBarDetails.alertBarLink !== undefined && alertBarDetails.alertBarLink.length > 0 ? 
          <SmartLink 
            data={alertBarDetails.alertBarLink[0].smartLink}
            className={`nav-alert__link`}
          >
            {alertBarDetails.alertBarLink[0].linkText}
            <ArrowRightBlue/>
          </SmartLink>
        : null}
        <button 
          onClick={dismissAlert}
          className={`nav-alert__dismiss`}
          tabIndex={showAlertBar ? null : -1}
        >
          Dismiss
        </button>
      </div>
    </div>
  )
}

const componentData = () => {  
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsNav {
        alertBar {
          alertBarEndTime
          alertBarStartTime
          alertBarLink {
            linkText
            smartLink {
              ...smartLinkFragment
            }
          }
          alertBarText
        }
      }
    }
  `)
  return data
}