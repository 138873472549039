import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router'
import AnimatedLink from './animatedLink';
import { treeParentRecursive, generateSlug } from './../../helpers/urlBuilder';
import PdfLink from './pdf-link';

interface props {
  className?: string
  data: any
  children: any
  onClick?: any
  onMouseEnter?: any
}

export default function SmartLink({data, className, children, onClick, onMouseEnter}: props) {
  if (!data) return null  
  const pathName = useSelector(state => state.siteState.pathName);
  
  const {
    linkToStickyNavPoint,
    linkToSpecificArticleHubCategory,
    articleHubCategory,
    linkUrl,
    pageToLinkTo,
    stickyNavPointLink,
    useExternalUrl,
    linkToSpecificProductFilter,
    filter,
    product,
    linkToAPdfDownload,
    pdfToDownload,
    ibPdf,
    disable
  } = data;
  
  
  const getUrl = function(withoutQueryString) {
    if(disable) return null;
    let parents = [];
    // If page to link to hasnt been linked return hash
    if (pageToLinkTo == null) return '#';
    // Home conversion
    if (pageToLinkTo.slug == 'home') return '/';

    // Build slug 
    treeParentRecursive(pageToLinkTo, parents);
    let slug = generateSlug(pageToLinkTo.slug, parents);


    // withoutQueryString relates to same page updates
    if (!withoutQueryString) {
      
      // Add link query strings
      // Sticky nav point
      if (linkToStickyNavPoint) {
        slug = `${slug}/?section=${stickyNavPointLink.stickyNavId}`;
      }

      // Article Hub Category
      if (linkToSpecificArticleHubCategory && articleHubCategory) {
        let category = articleHubCategory.categoryTitle.replace(/\s+/g, '-');
        slug = `${slug}/?category=${category}`;
      }

      // Product/Filter Query string
      if (linkToSpecificProductFilter && (filter || product)) {
        let filterString = '';
        let productString = '';
        let connected = filter && product ? `&` : '';

        if (filter) filterString = `filter=${filter.categoryTitle.replace(/\s+/g, '-')}`;
        if (product) productString = `product=${product.productTitle.replace(/\s+/g, '-')}`;

        slug = `${slug}?${filterString}${connected}${productString}`;
      }
    }
    
    return slug;
  }
  function onSameLocationClick(){
    if(getUrl()){
      document.location.replace( getUrl() );
    }       
    
  }
  function checkSameLocationQueryChange() {
    let url = getUrl(true);    
    return pathName.includes(url)
  }
  
  function executeJSLink(e, js){
    new Function(js)();
    e.preventDefault();
  }


  if (useExternalUrl) {
        
    if(linkUrl.indexOf("javascript:") > -1){
      return (<a 
        href="javascript:void(0)"
        target="_self" 
        className={className} 
        onClick={e => executeJSLink(e, linkUrl)}
        onMouseEnter={onMouseEnter}        
      >
        {children}
      </a>)
    }
    
    return (
      <a 
        href={linkUrl} 
        target="_blank" 
        className={className} 
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </a>
    )
    
  } else if (linkToAPdfDownload) {
    return ibPdf 
    ? <PdfLink
        className={className}
        pdfLink={ibPdf}
        isExternal={false}
        isDownload={false}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </PdfLink>
    : <a 
        href={pdfToDownload.url} 
        target="_blank" 
        className={className} 
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </a>
  } else if (checkSameLocationQueryChange()) {
    
    return (
      <a 
        target="_self"
        href={getUrl()} 
        className={className} 
        onClick={onSameLocationClick}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </a>
    )
  } else {
    
    return (
      <AnimatedLink 
        to={getUrl()} 
        className={className} 
        onClick={onSameLocationClick}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </AnimatedLink>
    )
  }
}