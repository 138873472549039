import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AnimatedLink from '../animatedLink';
import SmartLink from './../smart-link';

interface props {
  data: any
}

export default function NavSecondaryMenu({data}: props) {
  
  const {
    subNavTextLinks,
    subNavButtonLinks,
    subNavTextLinksTitle,
    subNavButtonLinksTitle,
    overviewLink
  } = data;
  
  const removeAllHover = (e?:any) => {

    
    
    const primaryLinks = document.querySelectorAll('.nav__primary-item');
    for (let i = 0; i < primaryLinks.length; i++) {
      const link = primaryLinks[i];
      link.classList.remove('hover');
    }
  }

  return (
    <div className={`nav-secondary`} onMouseLeave={(e) => removeAllHover(e)}>
      <div className="outer-container">
        {subNavButtonLinks.length > 0 ? 
          <div className="nav-secondary__right-background"></div>
        : null}
        <div className="inner-container">
          <div className="nav-secondary__left">
            <h2 className="nav-secondary__left-title">{subNavTextLinksTitle}</h2>
            <div className="nav-secondary__left-items">
              {overviewLink ?
                <SmartLink 
                  data={overviewLink} 
                  className="nav-secondary__left-item"                    
                >
                  <h3>Overview</h3>
                </SmartLink> : null
              }
              {subNavTextLinks.map((link, index) => {
                return (
                  <SmartLink 
                    className="nav-secondary__left-item"
                    data={link.smartLink}
                    key={`link-${index}`}
                    onClick={removeAllHover}
                  >
                    <img src={link.linkIcon.url} alt={link.linkIcon.alt}/>
                    {/* <object type="image/svg+xml" data={link.linkIcon.url} title={link.linkIcon.alt}></object> */}
                    <h3>{link.linkTitle}</h3>
                    <p>{link.linkDescription}</p>
                  </SmartLink>
                )
              })}
            </div>     
            
             
          </div>
          {subNavButtonLinks.length ? 
            <div className="nav-secondary__left extended">
              <h2 className="nav-secondary__left-title excempt">{subNavButtonLinksTitle}</h2>
              <div className="nav-secondary__left-items">
                {subNavButtonLinks.map((link, index) => {
                  return (
                    <SmartLink 
                      className="nav-secondary__left-item"
                      data={link.smartLink}
                      key={`link-${index}`}
                      onClick={removeAllHover}
                    > 
                    <img src={link.linkIcon.url} alt={link.linkIcon.alt}/>
                      {/* <object type="image/svg+xml" data={link.linkIcon.url} title={link.linkIcon.alt}></object> */}
                      <h3>{link.linkTitle}</h3>
                    </SmartLink>
                  )
                })}
              </div>
            </div>
          : null}
          {false ? 
            <div className="nav-secondary__right">
              <h2 className="nav-secondary__right-title">{subNavButtonLinksTitle}</h2>
              <div className="nav-secondary__right-items">
                {subNavButtonLinks.map((link, index) => {
                  return (
                    <SmartLink 
                      className="nav-secondary__right-item"
                      data={link.smartLink}
                      key={`link-${index}`}
                      onClick={removeAllHover}
                    > 
                    <img src={link.linkIcon.url} alt={link.linkIcon.alt}/>
                      {/* <object type="image/svg+xml" data={link.linkIcon.url} title={link.linkIcon.alt}></object> */}
                      <h3>{link.linkTitle}</h3>
                    </SmartLink>
                  )
                })}
              </div>
            </div>
          : null}
        </div>
      </div>
    </div>
  )
}