import React, { useState, useEffect, useRef } from 'react';
import { hideOnScroll } from '../../../helpers/hide-on-scroll';
import AnimatedLink from '../animatedLink';
import { handleMenuOpen } from './../../../state/state-site';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import LinkOutIcon from '../../../svg/link-out-white.svg'
// @ts-ignore
import ArrowDownBlue from '../../../svg/arrow-down-blue.svg'
import SmartLink from './../smart-link';
import NavSearchMobile from './nav-search-mobile';
import { isMobile } from './../../../helpers/breakpoints';


interface props {
  data: any,
  isWebView?: boolean
}

export default function NavMobileMenu({data, isWebView}: props) {
  const dispatch = useDispatch();
  const menuOpen = useSelector(state => state.siteState.menuOpen);
  const menuEl = useRef(null);
  const [NavSearchActive, setNavSearchActive] = useState(false);
  const links = [];
  let mobNavMounted = false;
  let searchClickEvent = null;

  links.push(...data.primaryNavLinks)
  links.push(...data.secondaryNavLinks)

  useEffect(() => {
    if (!mobNavMounted) {
      // @ts-ignore
      if(!isWebView){
        hideOnScroll({ navbarSelector: '.nav-mobile', hidingClass: 'hide-nav', pollingInterval: 600});
      }
      mobNavMounted = true;
    }
    setSearchClickOut();
    return () => {
      document.getElementById('main-content').removeEventListener("mousedown", searchClickEvent)
    };
  },[])

  function triggerAccordion(index: number) {
    let accordionTextEl = document.getElementById(`menu-accordion-${index}`);
    let accordionIconEl = document.getElementsByClassName(`menu-accordion-icon-${index}`)[0];
    let elHeight = accordionTextEl.scrollHeight + 30;

    if (accordionTextEl.classList.contains('active')){
      accordionTextEl.style.maxHeight = 0  + "px";
      accordionTextEl.classList.remove('active');
      accordionIconEl.classList.remove('active');
    } else {
      accordionTextEl.style.maxHeight = elHeight + "px";
      accordionTextEl.classList.add('active');
      accordionIconEl.classList.add('active');
    }
  }

  function handleBugerClick() {
    if (menuOpen) {
      document.querySelector('.tl-edges').classList.remove("nav-open");
      dispatch(handleMenuOpen(false))
      // @ts-ignore
      setTimeout(() => document.querySelector('.nav-burger').focus(), 300);
    } else {
      document.querySelector('.tl-edges').classList.add("nav-open");
      dispatch(handleMenuOpen(true))
      // @ts-ignore
      setTimeout(() => document.querySelector('.nav-burger').focus(), 300);
    }
  }

  const setSearchClickOut = () => {
    document.getElementById('main-content').addEventListener("mousedown", searchClickEvent = (e) => {
      if (NavSearchActive) setNavSearchActive(false);
    }, false);
  }

  function loginButton(): void {
    var newWindow = window.open("https://ib.bankvic.com.au/bvib/signon/login.aspx", 'MVP3', "toolbar=yes,menubar=no,location=no,hotkeys=no,directories=no,scrollbars=yes,resizable=yes,status=yes,personalbar=no,self.resizeTo(screen.availWidth,screen.availHeight)");
    newWindow.focus();
  }

  return (
    <div className={`nav-mobile ${menuOpen ? 'menu-open' : ''} ${NavSearchActive ? 'search-active' : ''} ${isWebView ? 'hide-nav' : ''}`} data-datocms-noindex>
      <div className="nav-mobile__top-menu">
        <button 
          className={menuOpen ? "nav-burger active" : "nav-burger"}
          tabIndex={menuOpen  ? 0 : 1}
          aria-expanded={menuOpen ? 'true' : 'false'}
          aria-label={menuOpen ? "button - close navigation menu" : "button - open navigation menu"} 
          role="button" 
          aria-controls="navigation"
          onClick={handleBugerClick}
        >
          <div className="nav-burger__bar-1"></div>
          <div className="nav-burger__bar-2"></div>
          <div className="nav-burger__bar-3"></div>
        </button>
        <AnimatedLink 
          to={'/'} 
          className={`nav-mobile__logo`} 
          ariaLabel={`Bankvic Logo - Button - Click to go back to the homepage`}
        >
          <img src={data.bankvicLogo.url} alt={data.bankvicLogo.alt}/>
        </AnimatedLink>
        <div className="nav-mobile__buttons">
          <NavSearchMobile setNavSearchActive={setNavSearchActive}/>
          <button 
            onClick={loginButton}
            className="nav-mobile__login-button"
            aria-label={`Login Button`}
          >
            Login <LinkOutIcon aria-hidden={`true`}/>
          </button>
        </div>
      </div>
      <div 
        className={`nav-mobile__menu mobile-menu ${menuOpen ? 'active' : ''}`}
        ref={menuEl}
      >
        {links.map((link, index: number) => {
          return (
            <div 
              className="mobile-menu__accordion"
              key={`accordion-${index}`}
            >
              <button 
                className="mobile-menu__accordion-title"
                onClick={() => triggerAccordion(index)}
              >
                <span>
                  {link.topNavTitle}
                  <ArrowDownBlue className={`menu-accordion-icon-${index}`}/>
                </span>
              </button>
              <div 
                  className="mobile-menu__accordion-text"
                  id={`menu-accordion-${index}`}
                >
                {link.overviewLink &&
                  <SmartLink 
                    data={link.overviewLink} 
                    className="mobile-menu__accordion-overview"
                    onClick={() => handleBugerClick()}
                  >
                    Overview
                  </SmartLink>
                }
                {link.subNavTextLinksTitle.length > 0 ? 
                  <p className="mobile-menu__accordion-subtitle">{link.subNavTextLinksTitle}</p>
                : null}
                {link.subNavTextLinks.map((sublink, i: number) => {
                  return (
                    <li className={`mobile-menu__accordion-link ${i == 0 ? 'title' : ''}`} key={`link-${i}`}>
                      <SmartLink 
                        data={sublink.smartLink} 
                        onClick={() => handleBugerClick()}
                      >
                        <img src={sublink.linkIcon.url} alt={sublink.linkIcon.alt}/>
                        <span className={`mobile-menu__accordion-link-title`}>
                          {sublink.linkTitle}
                        </span>
                      </SmartLink>
                    </li>
                  )
                })}
                {link.subNavButtonLinksTitle.length > 0 ? 
                  <p className="mobile-menu__accordion-subtitle">{link.subNavButtonLinksTitle}</p>
                : null}
                {link.subNavButtonLinks.map((sublink, i: number) => {
                  return (
                    <li className={`mobile-menu__accordion-link ${i == 0 ? 'title' : ''}`} key={`link-${i}`}>
                      <SmartLink data={sublink.smartLink} onClick={() => handleBugerClick()}>
                        <img src={sublink.linkIcon.url} alt={sublink.linkIcon.alt}/>
                        <span className={`mobile-menu__accordion-link-title`}>
                          {sublink.linkTitle}
                        </span>
                      </SmartLink>
                    </li>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}