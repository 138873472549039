import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { parseHTML } from './../../helpers/parseHTML';
import AnimatedLink from './animatedLink';
import animateScrollTo from 'animated-scroll-to';
// @ts-ignore
import ArrowRightBlue from '../../svg/arrow-right-blue.svg'
// @ts-ignore
import CircleBlue from '../../svg/circle-blue.svg'
// @ts-ignore
import BackToTop from '../../svg/back-to-top.svg'
// @ts-ignore
import ArrowDownBlue from '../../svg/arrow-down-blue.svg'
import { isTablet, isMobile } from 'react-device-detect';
import SmartLink from './smart-link';

declare global {
  interface Window {
    ciscoBubbleChat: any;
  }
}

interface IFooterProps {
  hideMainFooter?: boolean,
  isWebView?: boolean
}

export default function Footer({ hideMainFooter, isWebView }: IFooterProps) {
  const data = componentData();
  const [chatButton, setChatButton] = useState(false);
  let footerMounted = false;  
  data.contactItems.forEach((item)=>{
    if(item.contactItemText.indexOf("href=\"get-help/?section=branches") > -1){
      item.contactItemText = item.contactItemText.replace("href=\"get-help/?section=branches", "href=\"/get-help/?section=branches")
    }
  });
  useEffect(() => {
    if (!footerMounted) {
      setTimeout(() => {
        if (window.ciscoBubbleChat && data.enableWebchatButton) setChatButton(true);
      }, 500);
      footerMounted = true;
    }
  })

  function backToTop(): void {
    animateScrollTo(0, { speed: 2000 })
  }

  function triggerAccordion(index: number) {
    let accordionTextEl = document.getElementById(`accordion-${index}`);
    let accordionIconEl = document.getElementsByClassName(`accordion-icon-${index}`)[0];
    let elHeight = accordionTextEl.scrollHeight + 30;

    if (accordionTextEl.classList.contains('active')) {
      accordionTextEl.style.maxHeight = 0 + "px";
      accordionTextEl.classList.remove('active');
      accordionIconEl.classList.remove('active');
    } else {
      accordionTextEl.style.maxHeight = elHeight + "px";
      accordionTextEl.classList.add('active');
      accordionIconEl.classList.add('active');
    }
  }

  if (hideMainFooter || isWebView) {
    return (
      <footer className={`footer`} id={`footer`}>
        <div className="footer__subfooter">
          <div className="outer-container">
            <div className="inner-container subfooter">
              <div className="footer__subfooter-wrapper">
                <img
                  className={`footer__subfooter-logo`}
                  src={data.subfooterLogo.url}
                  alt={data.subfooterLogo.alt}
                />
                <span className={`footer__subfooter-text`}>
                  {parseHTML(data.subfooterText)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }

  return (
    <footer className={`footer`} id={`footer`} data-datocms-noindex>
      <div className="outer-container">
        <div className="inner-container">
          <BackToTop
            tabIndex={0}
            aria-label={`Button - Back to top`}
            onClick={backToTop}
            onKeyPress={e => e.key === 'Enter' ? backToTop() : null}
            className={`footer__backtotop`}
          />
          <p className="footer__support-subtitle">{parseHTML(data.customerSupportSubtitle)}</p>
          <h2 className="footer__support-title">{parseHTML(data.customerSupportTitle)}</h2>
          <div className="footer__top">
            <div className="footer__support">
              <div className="footer__support-text">{parseHTML(data.customerSupportText)}</div>
              <div className="footer__support-status">
                {/* <span className="footer__support-status-icon"><CircleBlue/></span>
                <span className="footer__support-status-text">ONLINE NOW</span> */}
              </div>
              {chatButton &&
                <button
                  className="footer__support-cta"
                  onClick={() => window.ciscoBubbleChat ? window.ciscoBubbleChat.showChatWindow() : null}
                >
                  {data.customerSupportCtaText}<ArrowRightBlue />
                </button>
              }
            </div>
            <ul className="footer__contact">
              {data.contactItems.map((item, index) => {
                
                return (
                  <li className="footer__contact-item" key={`item-${index}`}>
                    <img src={item.contactItemIcon.url} alt={item.contactItemIcon.alt} />
                    <p className={`footer__contact-text`}>{parseHTML(item.contactItemText)}</p>
                  </li>
                )
              })}
            </ul>
            {(!isMobile || isTablet) &&
              <div className="footer__social">
                <p className="footer__social-title">{data.socialMediaText}</p>
                <ul className="footer__social-items">
                  {data.socialMediaItems.map((item, index) => {
                    return (
                      <SmartLink data={item.smartLink} className="footer__social-item" key={`item-${index}`}>
                        <img src={item.linkIcon.url} alt={item.linkIcon.alt} />
                      </SmartLink>
                    )
                  })}
                </ul>
              </div>
            }
          </div>
          <div className="footer__bottom">
            {data.footerLinks.map((linkList, index: number) => {
              return (
                <ul className={`footer__bottom-links`} key={`links-${index}`}>
                  <li className={`footer__bottom-link title`}>
                    {linkList.linkListTitle}
                  </li>
                  {linkList.links.map((link, i: number) => {
                    return (
                      <li className={`footer__bottom-link`} key={`link-${i}`}>
                        <SmartLink data={link.smartLink}>
                          {link.linkText}
                        </SmartLink>
                      </li>
                    )
                  })}
                </ul>
              )
            })}
            {data.footerLinks.map((linkList, index: number) => {
              return (
                <div
                  className="footer__bottom__accordion"
                  key={`accordion-${index}`}
                >
                  <button
                    className="footer__bottom__accordion-title"
                    onClick={() => triggerAccordion(index)}
                  >
                    <span>
                      {linkList.linkListTitle}
                      <ArrowDownBlue className={`accordion-icon-${index}`} />
                    </span>
                  </button>
                  <div
                    className="footer__bottom__accordion-text"
                    id={`accordion-${index}`}
                  >
                    {linkList.links.map((link, i: number) => {
                      return (
                        <li className={`footer__bottom-link`} key={`link-${i}`}>
                          <SmartLink data={link.smartLink}>
                            {link.linkText}
                          </SmartLink>
                        </li>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          {isMobile && !isTablet &&
            <div className="footer__social">
              <p className="footer__social-title">{data.socialMediaText}</p>
              <ul className="footer__social-items">
                {data.socialMediaItems.map((item, index) => {
                  return (
                    <SmartLink data={item.smartLink} className="footer__social-item" key={`item-${index}`}>
                      <img src={item.linkIcon.url} alt={item.linkIcon.alt} />
                    </SmartLink>
                  )
                })}
              </ul>
            </div>
          }
        </div>
      </div>
      <div className="footer__subfooter">
        <div className="outer-container">
          <div className="inner-container subfooter">
            <div className="footer__subfooter-wrapper">
              <img
                className={`footer__subfooter-logo`}
                src={data.subfooterLogo.url}
                alt={data.subfooterLogo.alt}
              />
              <span className={`footer__subfooter-text`}>
                {parseHTML(data.subfooterText)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const componentData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsFooter {
        enableWebchatButton
        contactItems {
          contactItemText
          contactItemIcon {
            url
            alt
          }
        }
        customerSupportCtaText
        customerSupportSubtitle
        customerSupportText
        customerSupportTitle
        footerLinks {
          linkListTitle
          links {
            linkText
            smartLink {
              ...smartLinkFragment
            }
          }
        }
        socialMediaText
        socialMediaItems {
          linkIcon {
            alt
            url
          }
          smartLink {
            ...smartLinkFragment
          }
        }
        subfooterText
        subfooterLogo {
          url
          alt
        }
      }
    }
  `)
  return data
}