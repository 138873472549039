import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AnimatedLink from './animatedLink';
import NavSecondaryMenu from './nav/nav-secondary-menu';
import NavMobileMenu from './nav/nav-mobile-menu';
import NavAlert from './nav/nav-alert';
import SmartLink from './smart-link';
import Fuse from 'fuse.js'
// @ts-ignore
import ChevronDownGrey from '../../svg/chevron-down-grey.svg'
// @ts-ignore
import SearchIcon from '../../svg/search.svg'
// @ts-ignore
import LinkOutIcon from '../../svg/link-out-white.svg'
// @ts-ignore
import Close from '../../svg/close-x.svg'
import { smartLinkFragment } from './../fragments/smart-link-fragement';
import NavSearchDesktop from './nav/nav-search-desktop';
import NavSearchDato from './nav/nav-search-dato';
import { hideOnScroll } from './../../helpers/hide-on-scroll';
import queryString from 'query-string';
import $ from 'jquery';
declare var window:Window;

interface props {
  // example?: any
  isWebView?: boolean
}

export default function Nav({isWebView}: props) {
  const data = componentData();
  const [searchActive, setNavSearchActive] = useState(false);
  const [noHover, setNoHover] = useState(false);
  let navMounted = false;
  let hoverTimeout = null;
  let keyword = null;
  let closed = false;
  const [defaultKeyword, setDefaultKeyword] = useState(null);
  data.primaryNavLinks = data.primaryNavLinks.map((item)=>{item.smartLink.disable = true;return item;});
  data.secondaryNavLinks = data.secondaryNavLinks.map((item)=>{item.smartLink.disable = true;return item;});
  //console.log(data);
  useEffect(() => {
    if (!navMounted) {
      // @ts-ignore
      hideOnScroll({ navbarSelector: '.nav', hidingClass: 'hide-nav', pollingInterval: 600});
      if(!isWebView){
        hideOnScroll({ navbarSelector: '.sticky-nav', hidingClass: 'top-nav-hidden', pollingInterval: 600});
      }
      navMounted = true;
    }
    let { query } = queryString.parse(location.search);
    
    if((document.location.search.indexOf("?q=") > -1) && !query){
      query = decodeURI(document.location.search.split("=")[1]);
      keyword = query;
    }
    if(keyword && !closed){
      
      setNavSearchActive(true);
      setDefaultKeyword(keyword);
      
    }
    
  })

  function loginButton(): void {
    // In the new window the toolbar has been turned on while in development so that we can use the back button
    var newWindow = window.open("https://ib.bankvic.com.au/bvib/signon/login.aspx", 'MVP3', "toolbar=yes,menubar=no,location=no,hotkeys=no,directories=no,scrollbars=yes,resizable=yes,status=yes,personalbar=no,self.resizeTo(screen.availWidth,screen.availHeight)");
    newWindow.focus();
  }

  
  function skipToContent(): void {
    const mainEl = document.getElementsByTagName('main')[0];
    mainEl.focus();
  }

  const resetLinkHover = (id) => {
    const primaryLinks = document.querySelectorAll('.nav__primary-item');
    for (let i = 0; i < primaryLinks.length; i++) {
      const link = primaryLinks[i];
      if (link.id !== id) {
        link.classList.remove('hover');
      }
    }
  }
  
  const addHoverClass = (id, event) => {    
    event?.preventDefault();
    event?.stopPropagation();
    const el = document.getElementById(id);
    
    clearTimeout(hoverTimeout)
    resetLinkHover(id);
    if(el?.classList.contains('hover')){
      $(document.activeElement).blur();
      removeHoverClass(id);
    }else{
      el!.classList.add('hover');
      el!.setAttribute('data-hover-timeout', 'true');
      setTimeout(hoverTimeout = () => el.removeAttribute('data-hover-timeout'), 500);
    }
    
    
  }
  
  const removeHoverClass = (id) => {
    const el = document.getElementById(id);
    if (el.hasAttribute('data-hover-timeout')) {
      // setTimeout(() => document.getElementById(id).classList.remove('hover'), 100);
    } else {
      el.classList.remove('hover');
    }
  }
  const removeAllHoverClass = ()=>{
    if (typeof document !== `undefined`){
      $(document.activeElement).blur();
    }
  }
  if (typeof window !== `undefined`){
    $(window).scroll(() => removeAllHoverClass());
  }
  
  
  return (
    <>
      <NavMobileMenu isWebView={isWebView} data={data}/>
      <nav className={`nav ${searchActive ? 'search-active' : ''} ${noHover ? 'no-hover' : ''}`} data-datocms-noindex>
        <NavAlert/>
        <a 
          href={``}
          onClick={e => e.preventDefault()}
          onKeyDown={e => e.keyCode === 13 ? skipToContent() : null}
          className="nav__skip"
          aria-label={`Skip to page content button`}
        >
          Skip to content
        </a>
        <div className="outer-container">
          <AnimatedLink 
            to={'/'} 
            className={`nav__logo`} 
            ariaLabel={`Bankvic Logo - Button - Click to go back to the homepage`}
          >
            <img src={data.bankvicLogo.url} alt={data.bankvicLogo.alt}/>
          </AnimatedLink>
          <div className="nav__primary">
            {data.primaryNavLinks.map((plink, index) => {
              return (
                
                <div 
                  className={`nav__primary-item`}
                  key={`key-${index}`}
                  id={`primary-item-${index}`}
                  onClick={(e) => addHoverClass(`primary-item-${index}`, e)}
                  onMouseLeave={(e) => removeHoverClass(`primary-item-${index}`)}
                >
                  
                  <SmartLink 
                    data={plink.smartLink}                     
                    className={`nav__primary-link primary`} 
                  >
                    {plink.topNavTitle} <ChevronDownGrey/>
                  </SmartLink>
                  <NavSecondaryMenu data={plink}/>
                </div>
              )
            })}
          </div>
          <div 
            className="nav__secondary"
          >
            {data.secondaryNavLinks.map((slink, index) => {
              return (
                <div 
                  className={`nav__primary-item`}
                  key={`key-${index}`}
                  id={`secondary-item-${index}`}
                  onClick={(e) => addHoverClass(`secondary-item-${index}`)}
                  onMouseLeave={(e) => removeHoverClass(`secondary-item-${index}`)}
                >
                  <SmartLink 
                    data={slink.smartLink} 
                    className={`nav__primary-link`} 
                  >
                    {slink.topNavTitle} <ChevronDownGrey/>
                  </SmartLink>
                  <NavSecondaryMenu data={slink}/>
                </div>
              )
            })}
            
            <NavSearchDesktop setNavSearchActive={(active) => {
              setNavSearchActive(active);
              if (!active) {
                setNoHover(true);
                setTimeout(() => setNoHover(false), 1000);
              }
            }} defaultKeyword={defaultKeyword} 
            setClosed={(cl)=>{
              if(cl){
                setNavSearchActive(false);
                setNoHover(true);
                setTimeout(() => setNoHover(false), 1000);
                
              }
              closed = cl;
              //console.log(">>>>", closed, searchActive)
            }}/>
            <button 
              onClick={loginButton}
              className="nav__login-button"
              aria-label={`Login Button`}
            >
              Login <LinkOutIcon aria-hidden={`true`}/>
            </button>
          </div>
        </div>
      </nav>
    </>
  )
}

const componentData = () => {  
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsNav {
        bankvicLogo {
          url
          alt
        }
        primaryNavLinks {
          topNavTitle
          smartLink {
            ...smartLinkFragment
          }
          overviewLink {
            ...smartLinkFragment
          }
          subNavButtonLinksTitle
          subNavButtonLinks {
            linkTitle
            linkIcon {
              url
              alt
            }
            smartLink {
              ...smartLinkFragment
            }
          }
          subNavTextLinksTitle
          subNavTextLinks {
            linkTitle
            linkDescription
            linkIcon {
              url
              alt
            }
            smartLink {
              ...smartLinkFragment
            }
          }
        }
        secondaryNavLinks {
          smartLink {
            ...smartLinkFragment
          }
          overviewLink {
            ...smartLinkFragment
          }
          topNavTitle
          subNavButtonLinksTitle
          subNavButtonLinks {
            linkTitle
            linkIcon {
              url
              alt
            }
            smartLink {
              ...smartLinkFragment
            }
          }
          subNavTextLinksTitle
          subNavTextLinks {
            linkTitle
            linkDescription
            linkIcon {
              url
              alt
            }
            smartLink {
              ...smartLinkFragment
            }
          }
        }
      }
    }
  `)
  return data
}